import React, { useEffect, useState } from 'react'
import { useTranslation, useCheckAndSetWrap } from '../../../hooks'
import * as Styled from './styles'
import {
  ButtonsContainer,
  LeftButton,
  RightButton
} from '../../CountrySelector/CountrySelectorModal/styles'
import LogoHp from '@veneer/core/dist/scripts/logos/hp/hp'
import Modal from '@veneer/core/dist/scripts/modal'
import { getCountryName } from '../../../helpers'
import { GapHandlerProps } from '../GapHandler'
import { useWindowResize } from '../../../hooks'

export const GapHandlerModal = ({
  showGapHandlerModal,
  setShowGapHandlerModal,
  selectedLocale,
  accountLocale,
  onCreateNewAccount,
  onExitFlow,
  onContinueEnrollment,
  isUnsupportedCountry
}: GapHandlerProps) => {
  const { getText, changeTranslation, supportedLocales } = useTranslation()

  const translatedAccountLocale = () => {
    const locale = isUnsupportedCountry ? selectedLocale : accountLocale
    const languageCode = accountLocale.split('_')[0]
    const countryCode = locale.split('_')[1]
    return Object.prototype.hasOwnProperty.call(supportedLocales, locale)
      ? getText(`locales.${accountLocale}.country`)
      : getCountryName(countryCode, languageCode)
  }

  const selectedCountryName = getText(
    `locales.${isUnsupportedCountry ? accountLocale : selectedLocale}.country`
  )
  const actualCountryName = translatedAccountLocale()

  const {
    shouldWrapButtons,
    leftButtonRef,
    rightButtonRef,
    buttonsContainerRef,
    buttonsPositionLoaded
  } = useCheckAndSetWrap(showGapHandlerModal)

  const [shouldWrapButtonsOnResize, setShouldWrapButtonsOnResize] =
    useState(false)
  const handleResize = () =>
    setShouldWrapButtonsOnResize(window.innerWidth <= 575)

  useWindowResize(handleResize)

  useEffect(() => {
    changeTranslation(selectedLocale)
  }, [changeTranslation, selectedLocale])

  const texts = isUnsupportedCountry
    ? {
        first: getText('error_modal.cancel_or_new_account.body.text_01', {
          country_to_enroll: actualCountryName
        }),
        second: getText('error_modal.cancel_or_new_account.body.text_02'),
        leftButton: getText('shared.cancel_enroll_button'),
        rightButton: getText('shared.new_account_button')
      }
    : {
        first: getText('error_modal.enroll_or_new_account.body.text_01', {
          actual_country: actualCountryName,
          country_to_enroll: selectedCountryName
        }),
        second: getText('error_modal.enroll_or_new_account.body.text_02', {
          actual_country: actualCountryName
        }),
        leftButton: getText('shared.enroll_button', {
          actual_country: actualCountryName
        }),
        rightButton: getText('shared.new_account_button')
      }

  const handleOnClose = () => {
    if (isUnsupportedCountry) {
      onExitFlow()
    } else {
      setShowGapHandlerModal(false)
      onContinueEnrollment()
    }
  }
  const handleCreateNewAccount = () => {
    onCreateNewAccount(selectedLocale)
  }

  const handleLeftButton = () => {
    if (isUnsupportedCountry) {
      onExitFlow()
    } else {
      onContinueEnrollment()
    }
  }

  return (
    <Modal
      show={showGapHandlerModal}
      onClose={handleOnClose}
      maxWidth={648}
      closeButton
      closeOnBlur={false}
      data-testid="gap-handler-modal"
    >
      <Styled.CountrySelectorGapHandlerContainer>
        <LogoHp appearance="singlecolor" size={64} />
        <Styled.CountrySelectorGapHandlerTitle>
          {getText('error_modal.title')}
        </Styled.CountrySelectorGapHandlerTitle>
        <Styled.CountrySelectorGapHandlerFirstDescription
          dangerouslySetInnerHTML={{ __html: texts.first }}
        />
        <Styled.CountrySelectorGapHandlerSecondDescription
          dangerouslySetInnerHTML={{ __html: texts.second }}
        />
        <ButtonsContainer
          ref={buttonsContainerRef}
          $shouldWrapButtons={shouldWrapButtons || shouldWrapButtonsOnResize}
          $buttonsPositionLoaded={buttonsPositionLoaded}
        >
          <LeftButton
            appearance="secondary"
            ref={leftButtonRef}
            onClick={handleLeftButton}
            $shouldWrapButtons={shouldWrapButtons || shouldWrapButtonsOnResize}
          >
            {texts.leftButton}
          </LeftButton>
          <RightButton
            ref={rightButtonRef}
            onClick={handleCreateNewAccount}
            $shouldWrapButtons={shouldWrapButtons || shouldWrapButtonsOnResize}
          >
            {texts.rightButton}
          </RightButton>
        </ButtonsContainer>
      </Styled.CountrySelectorGapHandlerContainer>
    </Modal>
  )
}
