import { useEffect, useRef, useState } from 'react'
import { checkAndSetButtonsWrap } from '../helpers'

export const useCheckAndSetWrap = (
  show: boolean,
  getText?: (
    translationPath: string,
    options?: Record<string, unknown>
  ) => string
) => {
  const [shouldWrapButtons, setShouldWrapButtons] = useState(false)
  const [buttonsPositionLoaded, setButtonsPositionLoaded] = useState(false)
  const leftButtonRef = useRef(null)
  const rightButtonRef = useRef(null)
  const buttonsContainerRef = useRef(null)
  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    const checkAndSetWrap = () => {
      const shouldWrap = checkAndSetButtonsWrap(
        rightButtonRef,
        leftButtonRef,
        buttonsContainerRef
      )
      setShouldWrapButtons(shouldWrap)
    }
    timeoutRef.current = window.setTimeout(() => {
      checkAndSetWrap()
      setButtonsPositionLoaded(true)
    }, 0)

    const handleResize = () => {
      checkAndSetWrap()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [show, getText])

  return {
    shouldWrapButtons,
    leftButtonRef,
    rightButtonRef,
    buttonsContainerRef,
    buttonsPositionLoaded
  }
}
