import React, { useCallback, useEffect, useState } from 'react'
import * as Styled from './styles'
import primitives from '@veneer/primitives'
import Modal from '@veneer/core/dist/scripts/modal'
import GlobeIcon from '@veneer/core/dist/scripts/icons/icon_globe'
import LanguageIcon from '@veneer/core/dist/scripts/icons/icon_language'
import Select, {
  SelectOnChangeType,
  SelectOption,
  SelectValue
} from '@veneer/core/dist/scripts/select'
import * as Flags from '@veneer/flags/dist'
import { OptionObject, SelectedOptionsObject } from '../../../types'
import { useTranslation, useCheckAndSetWrap } from '../../../hooks'
import { supportedCountries, supportedLanguages } from '../../../helpers'
import { CountrySelectorProps } from '../index'
import { Loading } from '../../Loading/Loading'
import { useWindowResize } from '../../../hooks'

export const CountrySelectorModal = ({
  showCountrySelector,
  setShowCountrySelector,
  accountLocale,
  onSave
}: CountrySelectorProps) => {
  const { getText, changeTranslation, supportedLocales } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [language, country] = accountLocale.split('_')
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsObject>(
    { country, language }
  )

  const languageOptions = supportedLanguages(
    `${selectedOptions.language}_${selectedOptions.country}`,
    supportedLocales
  )

  const countryOptions = supportedCountries(
    `${selectedOptions.language}_${selectedOptions.country}`,
    supportedLocales
  )

  const countriesWithFlags = (countryOptions: OptionObject[]) => {
    const countryFlag = (country: string) => {
      const countryCode = country.toUpperCase()
      const Flag = Flags[`Flag${countryCode}`]

      return <Flag size={28} />
    }

    return (countryOptions as OptionObject[])
      .sort((a: OptionObject, b: OptionObject) => {
        return a.label.localeCompare(b.label)
      })
      .map((country: OptionObject) => {
        return {
          value: country.value,
          label: country.label,
          icon: countryFlag(country.value)
        }
      })
  }

  const sortedLanguageList = (languageList: OptionObject[]) =>
    languageList.sort((a: OptionObject, b: OptionObject) => {
      return a.label.localeCompare(b.label)
    })

  const [languageList, setLanguageList] = React.useState<OptionObject[]>(
    sortedLanguageList(languageOptions[selectedOptions?.country])
  )
  const [countryList, setCountryList] = React.useState<OptionObject[]>(
    countriesWithFlags(countryOptions as OptionObject[])
  )
  const [languageValue, setLanguageValue] = React.useState<string[]>([
    selectedOptions.language
  ])
  const [countryValue, setCountryValue] = React.useState<React.ReactNode>([
    selectedOptions?.country
  ])

  const {
    shouldWrapButtons,
    leftButtonRef,
    rightButtonRef,
    buttonsContainerRef,
    buttonsPositionLoaded
  } = useCheckAndSetWrap(showCountrySelector, getText)

  const [shouldWrapButtonsOnResize, setShouldWrapButtonsOnResize] =
    useState(false)
  const handleResize = () =>
    setShouldWrapButtonsOnResize(window.innerWidth <= 575)

  useWindowResize(handleResize)

  useEffect(() => {
    changeTranslation(`${selectedOptions.language}_${selectedOptions.country}`)
  }, [changeTranslation, selectedOptions, countryValue, languageValue])

  useEffect(() => {
    setLanguageValue([
      languageList[selectedOptions.country]?.[0].value ||
        selectedOptions.language
    ])
  }, [languageList, selectedOptions])

  useEffect(() => {
    setCountryValue([
      (countryList as OptionObject[]).find(
        (countryItem) => countryItem.value === selectedOptions.country
      )?.value
    ])
  }, [countryList, selectedOptions.country])

  const resetSelectedOptions = useCallback(() => {
    setSelectedOptions({ country, language })
    const newCountryList = supportedCountries(
      `${language}_${country}`,
      supportedLocales
    )
    const newLanguageList = supportedLanguages(
      `${language}_${country}`,
      supportedLocales
    )
    setLanguageList(sortedLanguageList(newLanguageList[country]))
    setCountryList(countriesWithFlags(newCountryList as OptionObject[]))
    setIsLoading(false)
  }, [country, language, supportedLocales])

  useEffect(() => {
    if (showCountrySelector) {
      resetSelectedOptions()
    }
  }, [resetSelectedOptions, showCountrySelector])

  const handleCountryChange = (event: OptionObject) => {
    setLanguageValue([])
    const country = event.value
    const language = languageOptions[country][0].value
    setSelectedOptions({ language, country })

    const newCountryList = supportedCountries(
      `${language}_${country}`,
      supportedLocales
    )
    const newLanguageList = supportedLanguages(
      `${language}_${country}`,
      supportedLocales
    )

    setLanguageList(sortedLanguageList(newLanguageList[country]))
    setCountryList(countriesWithFlags(newCountryList as OptionObject[]))
    setCountryValue([
      (newCountryList as OptionObject[]).find(
        (countryItem) => countryItem.value === country
      )?.value
    ])
  }

  const handleLanguageChange = (event: OptionObject) => {
    setCountryValue([])
    const language = event.value
    setSelectedOptions((prev) => ({ ...prev, language }))

    const newCountryList = supportedCountries(
      `${language}_${selectedOptions.country}`,
      supportedLocales
    )
    const newLanguageList = supportedLanguages(
      `${language}_${selectedOptions.country}`,
      supportedLocales
    )

    setLanguageList(
      sortedLanguageList(newLanguageList[selectedOptions.country])
    )
    setCountryList(countriesWithFlags(newCountryList as OptionObject[]))
  }

  const handleOnSave = () => {
    setIsLoading(true)
    onSave(`${selectedOptions.language}_${selectedOptions.country}`)
  }

  const handleOnClose = () => {
    resetSelectedOptions()
    setShowCountrySelector(false)
  }

  return (
    <Modal
      show={showCountrySelector}
      onClose={handleOnClose}
      maxWidth={480}
      closeButton
      closeOnBlur={false}
      data-testid="country-selector-modal"
    >
      {isLoading && showCountrySelector ? (
        <Loading />
      ) : (
        <Styled.CountrySelectorContainer>
          <GlobeIcon color={primitives.color.gray12} size={48} />
          <Styled.CountrySelectorLabel>
            {getText('country_selector_modal.country.title')}
          </Styled.CountrySelectorLabel>
          <Styled.CountrySelectorSelectContainer>
            <Select
              label={getText('country_selector_modal.country.select_label')}
              onChange={handleCountryChange as SelectOnChangeType}
              options={countryList as SelectOption<string>[]}
              value={countryValue as SelectValue[]}
              clearIcon={false}
              data-testid="country-selector-select-country"
            />
          </Styled.CountrySelectorSelectContainer>
          <LanguageIcon color={primitives.color.gray12} size={48} />
          <Styled.LanguageSelectorLabel>
            {getText('country_selector_modal.language.title')}
          </Styled.LanguageSelectorLabel>
          <Styled.LanguageSelectorSelectContainer>
            <Select
              label={getText('country_selector_modal.language.select_label')}
              onChange={handleLanguageChange as SelectOnChangeType}
              options={languageList as SelectOption<string>[]}
              value={languageValue as SelectValue[]}
              clearIcon={false}
              data-testid="country-selector-select-language"
            />
          </Styled.LanguageSelectorSelectContainer>
          <Styled.ButtonsContainer
            ref={buttonsContainerRef}
            $shouldWrapButtons={shouldWrapButtons || shouldWrapButtonsOnResize}
            $buttonsPositionLoaded={buttonsPositionLoaded}
            data-testid="country-selector-buttons-container"
          >
            <Styled.LeftButton
              appearance="secondary"
              ref={leftButtonRef}
              onClick={handleOnClose}
              $shouldWrapButtons={
                shouldWrapButtons || shouldWrapButtonsOnResize
              }
            >
              {getText('shared.cancel_button')}
            </Styled.LeftButton>
            <Styled.RightButton
              ref={rightButtonRef}
              onClick={handleOnSave}
              $shouldWrapButtons={
                shouldWrapButtons || shouldWrapButtonsOnResize
              }
            >
              {getText('shared.save_button')}
            </Styled.RightButton>
          </Styled.ButtonsContainer>
        </Styled.CountrySelectorContainer>
      )}
    </Modal>
  )
}
