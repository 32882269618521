import styled from 'styled-components'
import primitives from '@veneer/primitives'
import Button from '@veneer/core/dist/scripts/button'

export const CountryButton = styled(Button)<{ $shouldCompact: boolean }>`
  && {
    padding: ${({ $shouldCompact }) =>
      $shouldCompact ? '6px 12px 6px 8px' : '6px 12px'};
    display: flex;
    align-items: center;
    border-radius: 8px;
  }
`

export const FlagContainer = styled.div<{ $shouldCompact: boolean }>`
  && {
    display: flex;
    margin-right: ${({ $shouldCompact }) => ($shouldCompact ? '4px' : '8px')};
  }
`

export const CountryCode = styled.span`
  && {
    font-family: 'Forma DJR UI', serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: ${primitives.color.hpBlue7};
  }
`
