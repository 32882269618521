import { useEffect } from 'react'

export const useWindowResize = (handleResize: () => void) => {
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])
}
